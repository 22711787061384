import React, { useState, useEffect } from 'react';
import './App.css';
import exampleImage from './example.png';
import { getWordsForDate } from './wordList';
import { Link } from 'react-router-dom';

// Add these type definitions at the top of the file
interface GameState {
  words: string[];
}

interface GameDate {
  year: number;
  month: number;
  day: number;
}

// Add this constant at the top of the file
const GAME_VERSION = '1.5.3'; // Increment this since we've modified the word list

// Helper function to get start of day in user's timezone
const getStartOfDay = (date: Date): Date => {
  // Convert to EST (UTC-5) by adding 5 hours to get correct day boundary
  return new Date(Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    5, 0, 0, 0  // 5am UTC = 12am EST
  ));
};

// First, add this style block near the top of your file, after the imports
const blinkKeyframes = `
  @keyframes blink {
    0%, 60% { 
      transform: scaleY(1);
      height: 6px;
      border-radius: 12px 12px 0 0;
    }
    65%, 90% { 
      transform: scaleY(1);
      height: 12px;
      border-radius: 12px;
    }
    95%, 100% { 
      transform: scaleY(1);
      height: 6px;
      border-radius: 12px 12px 0 0;
    }
  }

  @keyframes tongue {
    0%, 70%, 100% { transform: scaleY(0); }
    75%, 85% { transform: scaleY(1); }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

// Add this helper function near other utility functions
const getPuzzleNumber = (date: Date): number => {
  // Use the exact same start date as in the API (wordchains.ts)
  const startDate = new Date('2024-11-02T05:00:00Z');
  const targetDate = new Date(date.getTime());
  
  // Adjust both dates to EST midnight
  targetDate.setUTCHours(5, 0, 0, 0);
  
  // Calculate days since start
  const diffTime = targetDate.getTime() - startDate.getTime();
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  
  // The puzzle number should be sequential based on days since start
  // This will work for any date, even far into the future
  return diffDays + 1;
};

function App() {
  // Define gameDate first, checking for a test date in localStorage
  const [gameDate, setGameDate] = useState(() => {
    const testDateStr = localStorage.getItem('testDate');
    if (testDateStr) {
      return new Date(parseInt(testDateStr));
    }
    return new Date();
  });

  const isArchivedPuzzle = localStorage.getItem('isArchivedPuzzle') === 'true';
  const timestamp = localStorage.getItem('testDate') || '';

  // Use different keys for archived puzzles
  const getStorageKey = (key: string) => {
    return isArchivedPuzzle ? `archived_${key}_${timestamp}` : key;
  };

  // Add loading state near other state definitions
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Then initialize correctWords using gameDate
  const [correctWords, setCorrectWords] = useState<string[]>([]);

  // Define shouldResetGame first
  const shouldResetGame = () => {
    const now = new Date();
    const todayStart = getStartOfDay(now);
    const lastPlayed = localStorage.getItem('lastPlayed');
    
    // Check game version
    const savedVersion = localStorage.getItem('gameVersion');
    if (savedVersion !== GAME_VERSION) {
      // Preserve streaks before clearing
      const streakData = {
        currentStreak: localStorage.getItem('currentStreak'),
        winStreak: localStorage.getItem('winStreak'),
      };
      
      // Clear all localStorage
      localStorage.clear();
      
      // Restore streaks and set new version
      if (streakData.currentStreak) {
        localStorage.setItem('currentStreak', streakData.currentStreak);
      }
      if (streakData.winStreak) {
        localStorage.setItem('winStreak', streakData.winStreak);
      }
      localStorage.setItem('gameVersion', GAME_VERSION);
      return true;
    }
    
    // If we haven't played before, reset game but keep streaks
    if (!lastPlayed) {
      return true;
    }

    // Convert lastPlayed to a date for comparison
    const lastPlayedDate = new Date(parseInt(lastPlayed));
    const lastPlayedStart = getStartOfDay(lastPlayedDate);

    // Reset if it's a new day, but preserve streaks
    if (lastPlayedStart.getTime() !== todayStart.getTime()) {
      const keysToPreserve = ['currentStreak', 'winStreak'];
      const preservedData: Record<string, string> = {};
      
      // Save streak data
      keysToPreserve.forEach(key => {
        const value = localStorage.getItem(key);
        if (value) preservedData[key] = value;
      });
      
      // Clear game state
      const keysToRemove = [
        'gameOver',
        'isWin',
        'turnsLeft',
        'lastPlayed',
        'gameWords',
        'incorrectWords',
        'letterResults',
        'showColors',
        'hasSubmitted',
        'revealedCorrectLetters'
      ];
      keysToRemove.forEach(key => localStorage.removeItem(key));
      
      // Restore streak data
      Object.entries(preservedData).forEach(([key, value]) => {
        localStorage.setItem(key, value);
      });
      
      return true;
    }
    
    return false;
  };

  // Then initialize states that depend on it
  const [gameWords, setGameWords] = useState<GameState>(() => {
    if (shouldResetGame()) {
      return { words: [] };
    }
    
    // Try to load saved game state
    const savedGameWords = localStorage.getItem(getStorageKey('gameWords'));
    if (savedGameWords) {
      return JSON.parse(savedGameWords);
    }
    
    return { words: [] };
  });

  useEffect(() => {
    const fetchWords = async () => {
      try {
        const words = await getWordsForDate(gameDate);
        setCorrectWords(words);
        
        // Initialize or reset game words after fetching
        if (shouldResetGame() || !gameWords.words.length) {
          setGameWords({
            words: words.map((word: string, index: number) => {
              if (index === 0 || index === words.length - 1) {
                return word;
              }
              return word[0] + ' '.repeat(word.length - 1);
            })
          });
        }
        
        setIsLoading(false);
      } catch (error) {
        console.error('Failed to fetch words:', error);
        setError('Failed to load puzzle. Please try again later.');
        setIsLoading(false);
      }
    };
    fetchWords();
  }, [gameDate]);

  useEffect(() => {
    localStorage.setItem(getStorageKey('gameWords'), JSON.stringify(gameWords));
  }, [gameWords]);

  const [turnsLeft, setTurnsLeft] = useState(() => {
    if (shouldResetGame()) {
      return 3;
    }
    const storedTurns = localStorage.getItem(getStorageKey('turnsLeft'));
    return storedTurns ? parseInt(storedTurns) : 3;
  });
  const [hasSubmitted, setHasSubmitted] = useState(() => {
    if (shouldResetGame()) return false;
    const saved = localStorage.getItem(getStorageKey('hasSubmitted'));
    return saved === 'true';
  });
  const [incorrectWords, setIncorrectWords] = useState<number[]>(() => {
    if (shouldResetGame()) return [];
    const saved = localStorage.getItem(getStorageKey('incorrectWords'));
    return saved ? JSON.parse(saved) : [];
  });
  const [hasLoaded, setHasLoaded] = useState(false);
  const [showColors, setShowColors] = useState(() => {
    if (shouldResetGame()) return false;
    const saved = localStorage.getItem(getStorageKey('showColors'));
    return saved === 'true';
  });
  const [animationKey, setAnimationKey] = useState(0);
  const [showRules, setShowRules] = useState(() => {
    return localStorage.getItem('hasSeenRules') !== 'true';
  });
  const [gameOver, setGameOver] = useState(() => {
    if (shouldResetGame()) return false;
    const isOver = localStorage.getItem(getStorageKey('gameOver')) === 'true';
    const turns = localStorage.getItem(getStorageKey('turnsLeft'));
    return isOver || (turns !== null && parseInt(turns) === 0);
  });
  const [canPlay, setCanPlay] = useState(() => {
    if (shouldResetGame()) return true;
    const isOver = localStorage.getItem(getStorageKey('gameOver')) === 'true';
    const turns = localStorage.getItem(getStorageKey('turnsLeft'));
    return !isOver && (turns === null || parseInt(turns) > 0);
  });
  const [letterResults, setLetterResults] = useState<string[]>(() => {
    if (shouldResetGame()) return [];
    const saved = localStorage.getItem(getStorageKey('letterResults'));
    return saved ? JSON.parse(saved) : [];
  });

  // Add new state near the top of the App function
  const [revealedCorrectLetters, setRevealedCorrectLetters] = useState<Set<string>>(() => {
    if (shouldResetGame()) return new Set();
    const saved = localStorage.getItem(getStorageKey('revealedCorrectLetters'));
    return saved ? new Set(JSON.parse(saved)) : new Set();
  });

  // Add these near the top with other state definitions
  const [currentStreak, setCurrentStreak] = useState(() => {
    const saved = localStorage.getItem('currentStreak');
    return saved ? parseInt(saved) : 0;
  });

  // Add new state for animation
  const [isClosing, setIsClosing] = useState(false);

  // Add this new state near other state definitions
  const [letterAttempts, setLetterAttempts] = useState<Record<string, number>>(() => {
    if (shouldResetGame()) return {};
    const saved = localStorage.getItem(getStorageKey('letterAttempts'));
    return saved ? JSON.parse(saved) : {};
  });

  // Add this useEffect to persist letterAttempts
  useEffect(() => {
    localStorage.setItem(getStorageKey('letterAttempts'), JSON.stringify(letterAttempts));
  }, [letterAttempts]);

  useEffect(() => {
    setTimeout(() => {
      setHasLoaded(true);
    }, 100);
  }, []);

  useEffect(() => {
    const lastPlayed = localStorage.getItem('lastPlayed');
    const isGameOver = localStorage.getItem(getStorageKey('gameOver')) === 'true';
    const turns = localStorage.getItem(getStorageKey('turnsLeft'));
    
    if (shouldResetGame()) {
      setCanPlay(true);
      setGameOver(false);
      return;
    }
    
    // Update game state based on turns and game over status
    if (isGameOver || (turns !== null && parseInt(turns) === 0)) {
      setGameOver(true);
      setCanPlay(false);
    } else {
      setCanPlay(true);
      setGameOver(false);
    }
  }, []);

  useEffect(() => {
    // When rules are closed, mark that they've seen them
    if (!showRules) {
      localStorage.setItem('hasSeenRules', 'true');
    }
  }, [showRules]);

  useEffect(() => {
    localStorage.setItem(getStorageKey('letterResults'), JSON.stringify(letterResults));
    localStorage.setItem(getStorageKey('incorrectWords'), JSON.stringify(incorrectWords));
  }, [letterResults, incorrectWords]);

  const handleLetterChange = (wordIndex: number, letterIndex: number, value: string) => {
    if (!canPlay) return; // Don't allow changes if game is complete
    
    setHasSubmitted(false);
    setIncorrectWords([]);
    const newWords = [...gameWords.words];
    const currentWord = newWords[wordIndex];
    const newLetter = value === '' ? ' ' : value.toUpperCase();
    const newWord = 
      currentWord.substring(0, letterIndex) + 
      newLetter + 
      currentWord.substring(letterIndex + 1);
    newWords[wordIndex] = newWord;
    setGameWords({ words: newWords });
  };

  const isWordComplete = (word: string) => {
    return !word.includes(' ');
  };

  // Add new win streak state separately from existing currentStreak
  const [winStreak, setWinStreak] = useState(() => {
    const saved = localStorage.getItem('winStreak');
    return saved ? parseInt(saved) : 0;
  });

  // Add useEffect to persist win streak separately
  useEffect(() => {
    localStorage.setItem('winStreak', winStreak.toString());
  }, [winStreak]);

  // Modify checkWords to handle streaks correctly
  const checkWords = () => {
    setHasSubmitted(true);
    setShowColors(false);
    setAnimationKey(prev => prev + 1);
    const currentWords = gameWords.words;
    const newIncorrectWords: number[] = [];
    
    // Track newly revealed correct letters and their attempts
    const newRevealedLetters = new Set(revealedCorrectLetters);
    const newLetterAttempts = { ...letterAttempts };
    
    for (let i = 1; i < currentWords.length - 1; i++) {
      for (let j = 1; j < currentWords[i].length; j++) {
        if (currentWords[i][j] === correctWords[i][j]) {
          const letterKey = `${i}-${j}`;
          if (!letterAttempts[letterKey]) {
            // If this is the first time getting this letter correct, record the attempt number
            newLetterAttempts[letterKey] = 4 - turnsLeft;
          }
          newRevealedLetters.add(letterKey);
        }
      }
    }
    
    setLetterAttempts(newLetterAttempts);
    setRevealedCorrectLetters(newRevealedLetters);
    localStorage.setItem(getStorageKey('revealedCorrectLetters'), JSON.stringify(Array.from(newRevealedLetters)));
    
    for (let i = 1; i < currentWords.length - 1; i++) {
      if (currentWords[i] !== correctWords[i]) {
        newIncorrectWords.push(i);
      }
    }
    
    setTimeout(() => {
      setShowColors(true);
      
      // Track results for sharing
      const results: string[] = [];
      for (let i = 1; i < currentWords.length - 1; i++) {
        const word = currentWords[i];
        for (let j = 1; j < word.length; j++) {
          if (word[j] === correctWords[i][j]) {
            results.push('🟩');
          } else if (word[j] !== ' ') {
            results.push('🟧');
          }
        }
      }
      setLetterResults(results);
      localStorage.setItem(getStorageKey('letterResults'), JSON.stringify(results));
      
      const isArchivedPuzzle = localStorage.getItem('isArchivedPuzzle') === 'true';
      
      if (newIncorrectWords.length > 0) {
        // Handle incorrect guess
        setIncorrectWords(newIncorrectWords);
        localStorage.setItem(getStorageKey('incorrectWords'), JSON.stringify(newIncorrectWords));
        const newTurns = turnsLeft - 1;
        setTurnsLeft(newTurns);
        localStorage.setItem(getStorageKey('turnsLeft'), newTurns.toString());
        
        if (newTurns === 0) {
          // Game Loss
          setGameOver(true);
          setIsWin(false);
          setCanPlay(false);
          localStorage.setItem(getStorageKey('gameOver'), 'true');
          localStorage.setItem(getStorageKey('isWin'), 'false');
          
          // Reset win streak on loss (only for today's puzzle)
          if (!isArchivedPuzzle) {
            setWinStreak(0);
            localStorage.setItem('winStreak', '0');
            // Don't modify current streak on loss
          }
        }
        return;
      }
      
      // Game Win
      setGameOver(true);
      setIsWin(true);
      setCanPlay(false);
      localStorage.setItem(getStorageKey('gameOver'), 'true');
      localStorage.setItem(getStorageKey('isWin'), 'true');
      setIncorrectWords([]);
      
      // Update streaks on win (only for today's puzzle)
      if (!isArchivedPuzzle) {
        // Increment win streak
        const newWinStreak = winStreak + 1;
        setWinStreak(newWinStreak);
        localStorage.setItem('winStreak', newWinStreak.toString());
        
        // Increment current streak
        const newStreak = currentStreak + 1;
        setCurrentStreak(newStreak);
        localStorage.setItem('currentStreak', newStreak.toString());
      }
      
      localStorage.setItem('lastPlayed', Date.now().toString());
    }, 500);
  };

  const renderWord = (word: string, index: number) => {
    const correctLength = correctWords[index].length;
    const isCurrentWordComplete = !word.includes(' ');

    return (
      <div style={{ 
        display: 'flex', 
        gap: '8px',
        filter: 'drop-shadow(0 2px rgba(243, 114, 44, 0.25))',
        position: 'relative'
      }}>
        {Array.from({ length: correctLength }).map((_, letterIndex) => {
          // Check if this letter was previously revealed as correct
          const isRevealed = revealedCorrectLetters.has(`${index}-${letterIndex}`);
          
          let backgroundColor = index === 0 || index === gameWords.words.length - 1 || letterIndex === 0 
            ? (isDarkMode ? '#4a3f42' : '#ECC9D0')  // Darker shade for fixed letters
            : isRevealed 
              ? (isDarkMode ? '#4E8B72' : '#5EBB87')  // Even softer, more muted green
              : (isDarkMode ? '#333333' : 'white');  // Dark background for input boxes
          
          const currentLetter = word[letterIndex];
          const isLetterCorrect = currentLetter === correctWords[index][letterIndex];
          const isLetterIncorrect = hasSubmitted && 
            currentLetter && 
            currentLetter !== ' ' && 
            !isLetterCorrect &&
            !(index === 0 || index === gameWords.words.length - 1 || letterIndex === 0);

          if (currentLetter && currentLetter !== ' ' && 
              !(index === 0 || index === gameWords.words.length - 1 || letterIndex === 0)) {
            if (showColors && hasSubmitted && isCurrentWordComplete) {
              backgroundColor = isLetterCorrect 
                ? (isDarkMode ? '#4E8B72' : '#5EBB87')  // Even softer, more muted green
                : (isDarkMode ? '#C68341' : '#F6A247');  // Even softer, more muted orange
            }
          }

          let animationClass = '';
          if (hasLoaded) {
            animationClass = `flip flip-delay-${letterIndex}`;
          }
          if (hasSubmitted && currentLetter && currentLetter !== ' ' && 
              !(index === 0 || index === gameWords.words.length - 1 || letterIndex === 0)) {
            animationClass = `flip flip-delay-${letterIndex} submit-flip-${animationKey}`;
            if (isLetterIncorrect) {
              animationClass += ` shake`;
            }
          }

          return (
            <input
              key={`${letterIndex}-${animationKey}`}
              className={`letter-hidden ${animationClass}`}
              type="text"
              value={
                isRevealed ? correctWords[index][letterIndex] :  // Show correct letter if revealed
                (index === 0 || index === gameWords.words.length - 1 || letterIndex === 0
                  ? word[letterIndex]?.trim() || ''
                  : word[letterIndex] === ' ' ? '' : word[letterIndex] || '')
              }
              placeholder={
                index > 0 && index < gameWords.words.length - 1 && letterIndex > 0 
                  ? '•' 
                  : ''
              }
              disabled={
                isRevealed ||  // Disable input if letter was revealed as correct
                index === 0 || 
                index === gameWords.words.length - 1 || 
                letterIndex === 0 ||
                !canPlay
              }
              onChange={(e) => {
                const newValue = e.target.value.slice(-1); // Only take the last character entered
                handleLetterChange(index, letterIndex, newValue);
                
                // Wait a brief moment before moving focus to ensure the current value is set
                setTimeout(() => {
                  const target = e.target as HTMLInputElement;
                  const nextInput = target.nextElementSibling as HTMLInputElement;
                  if (nextInput && newValue) {
                    nextInput.focus();
                  }
                }, 50);
              }}
              onKeyDown={(e) => {
                // Only handle backspace and enter
                if (e.key === 'Backspace') {
                  e.preventDefault();
                  const target = e.target as HTMLInputElement;
                  
                  // Clear current input if it has a value
                  if (target.value) {
                    handleLetterChange(index, letterIndex, '');
                  }
                  // Move to previous input if current is empty
                  else if (letterIndex > 0) {
                    const prevInput = target.previousElementSibling as HTMLInputElement;
                    if (prevInput) {
                      prevInput.focus();
                    }
                  }
                }
                else if (e.key === 'Enter') {
                  const allWordsComplete = gameWords.words.every(isWordComplete);
                  if (allWordsComplete) {
                    checkWords();
                  }
                }
              }}
              maxLength={1}
              style={{
                width: '38px',
                height: '38px',
                textAlign: 'center',
                textTransform: 'uppercase',
                backgroundColor,
                border: 'none',
                borderRadius: '8px',
                margin: '0',
                padding: '0',
                fontSize: '22px',
                fontWeight: 'bold',
                outline: 'none',
                boxShadow:'(0 4px rgba(254, 200, 154, 0.25)',
                fontFamily: '"DM Sans", sans-serif',
                color: isDarkMode ? '#ffffff' : '#000000',
                outlineColor: isDarkMode ? '#ffffff' : '#000000',
              }}
            />
          );
        })}
      </div>
    );
  };

  const renderHearts = (count: number) => {
    return Array.from({ length: 3 }).map((_, index) => (
      <span key={index} style={{ 
        marginRight: '6px',
        display: 'inline-block'
      }}>
        <svg 
          width="36" 
          height="36" 
          viewBox="0 0 24 24" 
          fill={index < count ? "#ff4d4d" : "#FAE1DD"}
        >
          <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
        </svg>
      </span>
    ));
  };

  useEffect(() => {
    localStorage.setItem(getStorageKey('showColors'), showColors.toString());
    localStorage.setItem(getStorageKey('hasSubmitted'), hasSubmitted.toString());
  }, [showColors, hasSubmitted]);

  useEffect(() => {
    localStorage.setItem(getStorageKey('revealedCorrectLetters'), JSON.stringify(Array.from(revealedCorrectLetters)));
  }, [revealedCorrectLetters]);

  // Add useEffect to save streak whenever it changes
  useEffect(() => {
    localStorage.setItem('currentStreak', currentStreak.toString());
  }, [currentStreak]);

  // Modify the modal close handler
  const handleCloseRules = () => {
    setIsClosing(true);
    setTimeout(() => {
      setShowRules(false);
      setIsClosing(false);
    }, 200); // Decreased from 300ms to 200ms
  };

  // Add new state for dark mode near other state definitions
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const saved = localStorage.getItem('isDarkMode');
    return saved === 'true';
  });

  // Add useEffect to persist dark mode setting
  useEffect(() => {
    localStorage.setItem('isDarkMode', isDarkMode.toString());
    // Apply dark mode to body
    document.body.style.backgroundColor = isDarkMode ? '#1a1a1a' : '#f8edeb';
    document.documentElement.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  // Add new state for tracking win/loss
  const [isWin, setIsWin] = useState(() => {
    if (shouldResetGame()) return false;
    return localStorage.getItem(getStorageKey('isWin')) === 'true';
  });

  // Add new state for puzzle number near other state definitions
  const [puzzleNumber, setPuzzleNumber] = useState(() => {
    return getPuzzleNumber(gameDate);
  });

  // Add style element for animations
  const allAnimations = `
    ${blinkKeyframes}
    @keyframes fadeIn {
      from { opacity: 0; }
      to { opacity: 1; }
    }
    @keyframes fadeOut {
      from { opacity: 1; }
      to { opacity: 0; }
    }
    @keyframes scaleIn {
      from { transform: scale(0.8); opacity: 0; }
      to { transform: scale(1); opacity: 1; }
    }
    @keyframes scaleOut {
      from { transform: scale(1); opacity: 1; }
      to { transform: scale(0.8); opacity: 0; }
    }
  `;

  return (
    <div className="App" style={{ 
      backgroundColor: isDarkMode ? '#1a1a1a' : '#f8edeb', 
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      paddingBottom: '120px',
      position: 'relative'
    }}>
      <style>{allAnimations}</style>
      <div className="game-header" style={{ 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2px 16px',
        width: '100%',
        position: 'relative'
      }}>
        <Link
          to="/contact"
          className="contact-button"
          style={{
            position: 'absolute',
            left: '20px',
            top: '50%',
            transform: 'translateY(-50%)',
            padding: '8px 16px',
            borderRadius: '8px',
            backgroundColor: isDarkMode ? '#4a3f42' : '#ECC9D0',
            border: 'none',
            fontSize: '16px',
            fontWeight: 'bold',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: isDarkMode ? '#ffffff' : '#000000',
            textDecoration: 'none',
            filter: 'drop-shadow(0 2px rgba(0, 0, 0, 0.25))'
          }}
        >
          FOLLOW
        </Link>

        <Link 
          to="/archive"
          style={{ 
            fontSize: '32px',
            fontWeight: 'bold',
            color: isDarkMode ? '#999' : '#000',
            width: 'auto',
            textAlign: 'center',
            textDecoration: 'none',
            cursor: 'pointer',
            borderBottom: `4px solid ${isDarkMode ? '#999' : '#000'}`,  // 2px thickness
            paddingBottom: '3px',  // 3px spacing
            display: 'inline-block'  // Needed for border to work properly
          }}
        >
          #{puzzleNumber}
        </Link>

        <button
          onClick={() => setShowRules(true)}
          className="rules-button"
          style={{
            position: 'absolute',
            right: '20px',
            top: '50%',
            transform: 'translateY(-50%)',
            padding: '8px 16px',
            borderRadius: '8px',
            backgroundColor: isDarkMode ? '#4a3f42' : '#ECC9D0',
            border: 'none',
            fontSize: '16px',
            fontWeight: 'bold',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: isDarkMode ? '#ffffff' : '#000000',
            filter: 'drop-shadow(0 2px rgba(0, 0, 0, 0.25))'
          }}
        >
          RULES
        </button>
      </div>

      {isLoading ? (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '60vh'
        }}>
          <img 
            src="/loader.png" 
            alt="Loading puzzle..." 
            style={{
              width: '48px',
              height: '48px',
              animation: 'rotate 1s linear infinite',
              transformOrigin: 'center'
            }}
          />
        </div>
      ) : error ? (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '60vh',
          fontSize: '24px',
          color: 'red'
        }}>
          {error}
        </div>
      ) : (
        <>
          {showRules && (
            <div 
              onClick={handleCloseRules}
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1000,
                animation: isClosing ? 'fadeOut 0.2s ease-out' : 'fadeIn 0.2s ease-out'
              }}
            >
              <div 
                onClick={(e) => e.stopPropagation()}
                style={{
                  backgroundColor: isDarkMode ? '#2d2d2d' : 'white',
                  borderRadius: '16px',
                  maxWidth: '85%',
                  maxHeight: '85%',
                  position: 'relative',
                  fontFamily: '"DM Sans", sans-serif',
                  textAlign: 'left',
                  display: 'flex',
                  flexDirection: 'column',
                  animation: isClosing ? 'scaleOut 0.2s ease-out' : 'scaleIn 0.2s ease-out'
                }}
              >
                <div style={{
                  padding: '1px 24px',
                  borderBottom: isDarkMode ? '1px solid #444' : '1px solid #eee',
                  position: 'sticky',
                  top: 0,
                  backgroundColor: isDarkMode ? '#2d2d2d' : 'white',
                  borderTopLeftRadius: '16px',
                  borderTopRightRadius: '16px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                  <h2 style={{ 
                    margin: 0,
                    color: isDarkMode ? '#ffffff' : '#000000'
                  }}>How to Play</h2>
                  <button
                    onClick={handleCloseRules}
                    style={{
                      backgroundColor: 'transparent',
                      border: 'none',
                      fontSize: '48px',
                      cursor: 'pointer',
                      padding: '8px',
                      lineHeight: '1',
                      color: isDarkMode ? '#ffffff' : '#000000'
                    }}
                  >
                    ×
                  </button>
                </div>

                <div style={{
                  padding: '24px',
                  overflow: 'auto',
                  color: isDarkMode ? '#ffffff' : '#000000'
                }}>
                  <p style={{ marginTop: 0, marginBottom: '16px' }}>
                    <div>Couples is a daily word chain game by <a href="https://livecultures.substack.com/" target='_blank' style={{ color: isDarkMode ? '#ffffff' : '#000000' }}>Xiomara</a> and <a href="https://x.com/@0sebo" target='_blank' style={{ color: isDarkMode ? '#ffffff' : '#000000' }}>Osebo</a></div>
                    New puzzles every day at 4pm PST / 7pm EST
                    <br />
                    Guess the word pairs in 3 tries. Each missing word 
                    <br />
                    builds two common phrases. In the example below, the pairs are 
                    <br />
                    Go Down, Down Fall, Fall Back, Back Stage, and Stage Name:
                  </p>

                  <img 
                    src={exampleImage}
                    alt="Game example"
                    style={{
                      width: '300px',
                      height: 'auto',
                      marginBottom: '16px',
                      borderRadius: '8px',
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto'
                    }}
                  />

                  <div style={{ 
                    paddingLeft: '0',
                    textAlign: 'left',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    color: isDarkMode ? '#ffffff' : '#000000'
                  }}>
                    <div>The first and last words in the word phrase chain are given</div>
                    <div>Green highlights show correct letters</div>
                    <div>Orange highlights show incorrect letters</div>
                    <div>You can only play once a day</div>
                    <div>If you run into any issues or want to say hi, <a href="mailto:osebosebo@gmail.com" target='' style={{ color: isDarkMode ? '#ffffff' : '#000000' }}>reach out</a></div>
                    <div>~ ♥ XO</div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!canPlay && !gameOver && (
            <div style={{
              padding: '20px 20px',
              borderRadius: '12px',
              marginBottom: '20px',
              textAlign: 'center',
              color: isDarkMode ? '#ffffff' : '#000000',
            }} className="win-message">
              <h2>You win!</h2>
              <div style={{ 
                display: 'flex', 
                justifyContent: 'center',
                margin: '10px 0'
              }}>
                <div>
                  <div style={{ fontSize: '42px', fontWeight: 'bold' }}>
                    {currentStreak}
                  </div>
                </div>
              </div>
              <p>The answer was:</p>
              <p style={{ 
                fontWeight: 'bold', 
                fontSize: '18px',
                margin: '12px 0' 
              }}>
                {correctWords.join(' → ')}
              </p>
              <p>Come back tomorrow for new words...</p>
              <button
                onClick={() => {
                  const hearts = '❤️'.repeat(turnsLeft);
                  const firstWord = correctWords[0];
                  const lastWord = correctWords[correctWords.length - 1];
                  
                  // Create rows of emojis for each word (excluding first and last)
                  const emojiRows = correctWords.slice(1, -1).map((word, index) => {
                    const currentWord = gameWords.words[index + 1];
                    const wordEmojis = Array.from({ length: word.length }).map((_, i) => {
                      if (i === 0) {
                        return '🟨'; // Yellow square for first letter
                      }
                      const letterKey = `${index + 1}-${i}`;
                      const attempts = letterAttempts[letterKey];
                      
                      if (currentWord[i] === word[i]) {
                        // Color based on attempts
                        switch (attempts) {
                          case 1: return '🟩'; // First try - green
                          case 2: return '🟪'; // Second try - purple
                          case 3: return '🟦'; // Third try - blue
                          default: return '⬜';
                        }
                      } else if (currentWord[i] !== ' ') {
                        return '🟥';
                      } else {
                        return '⬜';
                      }
                    }).join('');
                    return wordEmojis;
                  });
                  
                  const scoreText = `www.couples.game #${puzzleNumber}\n${hearts}\n${firstWord}\n${emojiRows.join('\n')}\n${lastWord}`;
                  navigator.clipboard.writeText(scoreText);
                  alert('Score copied to clipboard!');
                }}
                style={{
                  padding: '20px 16px',
                  fontSize: '24px',
                  fontWeight: 'bold',
                  backgroundColor: '#5EBB87',
                  color: 'black',
                  border: 'none',
                  borderRadius: '16px',
                  cursor: 'pointer',
                  width: '242px',
                  filter: 'drop-shadow(0 4px rgba(254, 200, 154, 0.25))',
                  fontFamily: '"DM Sans", sans-serif',
                  marginTop: '10px',
                  marginBottom: '16px',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
              >
                Share your score
              </button>
              {/* New Follow button */}
              <a
                href="https://pyramidpatience.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: 'block',
                  padding: '20px 16px',
                  fontSize: '24px',
                  fontWeight: 'bold',
                  backgroundColor: '#5EBB87',
                  color: 'black',
                  border: 'none',
                  borderRadius: '16px',
                  cursor: 'pointer',
                  width: '242px',
                  filter: 'drop-shadow(0 4px rgba(254, 200, 154, 0.25))',
                  fontFamily: '"DM Sans", sans-serif',
                  marginTop: '10px',
                  marginBottom: '16px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  textDecoration: 'none'
                }}
              >
                Play Pyramid Patience
              </a>
            </div>
          )}

          {gameOver && (
            <div style={{
              padding: '20px 36px',
              borderRadius: '12px',
              marginBottom: '20px',
              textAlign: 'center',
              color: isDarkMode ? '#ffffff' : '#000000',
            }}>
              <h2>{isWin ? 'You win!' : "You're out of turns"}</h2>
              {!localStorage.getItem('isArchivedPuzzle') && (
                <div style={{ 
                  display: 'flex', 
                  justifyContent: 'center',
                  gap: '48px',
                  margin: '10px 0'
                }}>
                  <div>
                    <div style={{ fontSize: '14px', marginBottom: '4px' }}>Play Streak</div>
                    <div style={{ fontSize: '36px', fontWeight: 'bold' }}>{currentStreak}</div>
                  </div>
                  <div>
                    <div style={{ fontSize: '14px', marginBottom: '4px' }}>Win Streak</div>
                    <div style={{ fontSize: '36px', fontWeight: 'bold' }}>{winStreak}</div>
                  </div>
                </div>
              )}
              <p>
                {localStorage.getItem('isArchivedPuzzle') ? (
                  `${new Date(parseInt(localStorage.getItem('testDate') || '')).toLocaleDateString('en-US', { 
                    month: 'long', 
                    day: 'numeric',
                    year: 'numeric'
                  })}'s answer was:`
                ) : (
                  "The answer was:"
                )}
              </p>
              <p style={{ 
                fontWeight: 'bold', 
                fontSize: '18px',
                margin: '12px 0' 
              }}>
                {correctWords.join(' → ')}
              </p>
              <p>{isWin ? 'Come back tomorrow for new words at 4pm PST / 7pm EST' : 'Try new words tomorrow at 4pm PST / 7pm EST'}</p>
              <button
                onClick={() => {
                  const hearts = '❤️'.repeat(turnsLeft);
                  const firstWord = correctWords[0];
                  const lastWord = correctWords[correctWords.length - 1];
                  
                  // Create rows of emojis for each word (excluding first and last)
                  const emojiRows = correctWords.slice(1, -1).map((word, index) => {
                    const currentWord = gameWords.words[index + 1];
                    const wordEmojis = Array.from({ length: word.length }).map((_, i) => {
                      if (i === 0) {
                        return '🟨';
                      }
                      const letterKey = `${index + 1}-${i}`;
                      const attempts = letterAttempts[letterKey];
                      
                      if (currentWord[i] === word[i]) {
                        switch (attempts) {
                          case 1: return '🟩';
                          case 2: return '🟪';
                          case 3: return '🟦';
                          default: return '⬜';
                        }
                      } else if (currentWord[i] !== ' ') {
                        return '🟥';
                      } else {
                        return '⬜';
                      }
                    }).join('');
                    return wordEmojis;
                  });

                  const scoreText = `www.couples.game #${puzzleNumber}\n${hearts}\n${firstWord}\n${emojiRows.join('\n')}\n${lastWord}`;
                  navigator.clipboard.writeText(scoreText);
                  alert('Score copied to clipboard!');
                }}
                style={{
                  padding: '20px 16px',
                  fontSize: '24px',
                  fontWeight: 'bold',
                  backgroundColor: '#5EBB87',
                  color: 'black',
                  border: 'none',
                  borderRadius: '16px',
                  cursor: 'pointer',
                  width: '242px',
                  filter: 'drop-shadow(0 4px rgba(254, 200, 154, 0.25))',
                  fontFamily: '"DM Sans", sans-serif',
                  marginTop: '10px',
                  marginBottom: '16px',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
              >
                Share your score
              </button>
              {/* New Follow button */}
              <a
                href="https://pyramids.game"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: 'block',
                  padding: '20px 16px',
                  fontSize: '24px',
                  fontWeight: 'bold',
                  backgroundColor: '#FFD4A3',
                  color: 'black',
                  border: 'none',
                  borderRadius: '16px',
                  cursor: 'pointer',
                  width: '242px',
                  filter: 'drop-shadow(0 4px rgba(254, 200, 154, 0.25))',
                  fontFamily: '"DM Sans", sans-serif',
                  marginTop: '10px',
                  marginBottom: '16px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  textDecoration: 'none'
                }}
              >
                Play Pyramids.game
              </a>
              {/* Archives button */}
              <Link
                to="/archive"
                style={{
                  display: 'block',
                  padding: '20px 12px',
                  fontSize: '24px',
                  fontWeight: 'bold',
                  backgroundColor: isDarkMode ? '#4a3f42' : '#ECC9D0',
                  color: isDarkMode ? '#ffffff' : '#000000',
                  border: 'none',
                  borderRadius: '16px',
                  cursor: 'pointer',
                  width: '216px',
                  filter: 'drop-shadow(0 4px rgba(254, 200, 154, 0.25))',
                  fontFamily: '"DM Sans", sans-serif',
                  textDecoration: 'none',
                  margin: '0 auto'
                }}
              >
                Play past puzzles
              </Link>
            </div>
          )}

          <div style={{ position: 'relative' }}>
            <style>{blinkKeyframes}</style>
            {/* Eyes and mouth container */}
            <div style={{
              position: 'absolute',
              top: 'calc(100% - 15px)',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              pointerEvents: 'none',
              zIndex: 1
            }}>
              {/* Eyes */}
              <div style={{
                display: 'flex',
                gap: '50px',
                marginBottom: '4px',
                position: 'relative'
              }}>
                <div style={{
                  width: '18px',
                  height: '9px',
                  backgroundColor: '#fff',
                  borderRadius: '18px 18px 0 0',
                  animation: 'blink 8s infinite',
                  transformOrigin: 'center',
                  position: 'relative'
                }}>
                  <div style={{
                    width: '12px',
                    height: '12px',
                    backgroundColor: '#000',
                    borderRadius: '50%',
                    position: 'absolute',
                    bottom: '-3px',
                    left: '3px'
                  }}/>
                </div>
                <div style={{
                  width: '18px',
                  height: '9px',
                  backgroundColor: '#fff',
                  borderRadius: '18px 18px 0 0',
                  animation: 'blink 8s infinite',
                  transformOrigin: 'center',
                  position: 'relative'
                }}>
                  <div style={{
                    width: '12px',
                    height: '12px',
                    backgroundColor: '#000',
                    borderRadius: '50%',
                    position: 'absolute',
                    bottom: '-3px',
                    left: '3px'
                  }}/>
                </div>
              </div>
              {/* Mouth and Tongue Container */}
              <div style={{
                position: 'absolute',
                top: '4px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}>
                {/* Mouth */}
                <div style={{
                  width: '40px',
                  height: '6px',
                  backgroundColor: '#000',
                  borderRadius: '2px'
                }}/>
                {/* Tongue */}
                <div style={{
                  width: '10px',
                  height: '14px',
                  backgroundColor: '#ff4444',
                  borderRadius: '0 0 5px 5px',
                  transformOrigin: 'top',
                  animation: 'tongue 6s infinite',
                  position: 'absolute',
                  top: '4px'
                }}/>
              </div>
            </div>

            <div className="game-container" style={{ 
              backgroundColor: isDarkMode ? '#4a3f42' : '#ECC9D0',
              padding: '12px',
              borderRadius: '12px',
              fontFamily: '"DM Sans", sans-serif',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              filter: 'drop-shadow(0 30px rgba(243, 114, 44, 0.25))',
              display: 'inline-block',
              minWidth: 'min-content',
              marginBottom: '24px',
              marginTop: '24px',
              opacity: 1,
              pointerEvents: canPlay ? 'auto' : 'none'
            }}>
              <div className="turns-display" style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '12px'
              }}>
                {renderHearts(turnsLeft)}
              </div>

              <div className="word-list">
                {gameWords.words.map((word, index) => (
                  <div 
                    key={index} 
                    className={incorrectWords.includes(index) ? 'word-item shake' : 'word-item'}
                    style={{
                      backgroundColor: isDarkMode ? '#2d2d2d' : '#fae1dd',
                      padding: '8px',
                      borderRadius: '8px',
                      marginBottom: '8px'
                    }}
                  >
                    {renderWord(word, index)}
                  </div>
                ))}
              </div>
            </div>
          </div>
          
          <button
            onClick={checkWords}
            disabled={!gameWords.words.every(isWordComplete) || !canPlay}
            style={{
              padding: '20px 16px',
              fontSize: '24px',
              fontWeight: 'bold',
              backgroundColor: (!gameWords.words.every(isWordComplete) || !canPlay) 
                ? '#cccccc' 
                : '#5EBB87',  // Updated to even softer green
              color: 'white',
              border: 'none',
              borderRadius: '16px',
              cursor: (!gameWords.words.every(isWordComplete) || !canPlay) ? 'not-allowed' : 'pointer',
              width: '242px',
              filter: 'drop-shadow(0 4px rgba(254, 200, 154, 0.25))',
              fontFamily: '"DM Sans", sans-serif',
              display: (!canPlay || gameOver) ? 'none' : 'block',
              marginTop: '24px'
            }}
          >
            {!gameWords.words.every(isWordComplete) 
              ? "Fill out all words"
              : `Guess (${4 - turnsLeft}/3)`}
          </button>

          {/* Add date display for archived puzzles */}
          {localStorage.getItem('isArchivedPuzzle') && !gameOver && (
            <div style={{
              marginTop: '12px',
              textAlign: 'center',
              color: isDarkMode ? '#999' : '#666',
              fontSize: '16px',
              fontFamily: '"DM Sans", sans-serif'
            }}>
              {new Date(parseInt(localStorage.getItem('testDate') || '')).toLocaleDateString('en-US', { 
                month: 'long', 
                day: 'numeric',
                year: 'numeric'
              })}
            </div>
          )}

          {/* Replace the dark mode text button with an icon button */}
          <button
            onClick={() => setIsDarkMode(!isDarkMode)}
            style={{
              position: 'fixed',
              bottom: '20px',
              right: '8px',
              padding: '12px',
              backgroundColor: 'transparent',
              color: isDarkMode ? '#ffffff' : '#000000',
              border: 'none',
              cursor: 'pointer',
              fontFamily: 'system-ui',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 10
            }}
            className="theme-toggle"
            aria-label={isDarkMode ? "Switch to light mode" : "Switch to dark mode"}
          >
            <span 
              className={`theme-toggle-icon ${isDarkMode ? 'flipped' : ''}`}
              style={{ 
                fontSize: '32px',
                lineHeight: '1',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '34px',
                height: '34px'
              }}
            >
              {isDarkMode ? '◑' : '◐'}
            </span>
          </button>

          {/* Update copyright position to not conflict with the icon */}
          <div style={{
            position: 'absolute',
            bottom: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            fontSize: '16px',
            color: isDarkMode ? '#999' : '#666',
            fontFamily: '"DM Sans", sans-serif',
            fontWeight: 'bold',
            opacity: 0.4,
            marginBottom: '20px'
          }}>
            © Special Games, 2024
          </div>
        </>
      )}
    </div>
  );
}

export default App;

